.Summary {
    $base: &;

    &_wrapper {
        // display: grid;
        // grid-template-columns: 100%;
        width: 100%;
        max-width: 598px;
        display: flex;
        flex-grow: 1;
    }

    &_container {
        display: flex;
        width: 100%;
        min-width: 375px;
        flex-direction: column;
        padding: 30px 20px 20px;
        background: #fff;
        border-radius: 20px;
    }

    &_header_wrapper {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: flex-start;

        &_note {
            font-size: 0.7rem;
        }

        &_title {
            font-size: 1.125rem;
            margin-bottom: 1.2rem;
            color: #464646;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }

    &_body {
        display: grid;
        grid-template-columns: 1fr auto 1fr auto 1fr;
        height: 100%;
        align-items: center;

        &_col {
            display: flex;
            flex-direction: column;
            text-align: center;
        }
    }

    &_vertical_divider {
        width: 1rem;
        height: 80%;
        border-right: 1.2px solid rgb(220, 220, 220);
        margin-inline: 0.1rem;
    }


}

.CellWithIndicator {
    $base: &;

    &_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.5rem;
    }

    &_label {
        margin-inline: 0.2rem;
        color: #BCBCBC;
    }

    &_value {
        color: #464646;
    }


}