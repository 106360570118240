.progresses {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  //width: 76vw;
  width: 100%;
  .line {
    width: 10%;
    height: 2px;
    background-color: #e6faf6;
    &.completed {
      background: #63d19e;
      width: calc(10% - 10px);
    }
  }

  .step {
    background-color: #e6faf6;
    line-height: 0;
    display: flex;
    color: #fff;
    font-size: 14px;
    width: 8px;
    height: 8px;
    min-width: 8px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    &.active {
      line-height: 0;
      display: flex;
      background-color: #63d19e;
      color: #fff;
      font-size: 14px;
      width: 18px !important;
      min-width: 18px;
      height: 18px !important;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
  }
}

.assessment-progress-card {
  margin-top: 10px;
  border-radius: 20px;
  background: white;
  padding: 30px 30px;
  display: flex;
  justify-content: center;

  .assessment-test-title {
    font-size: 18px;
    font-weight: 600;
  }
}

.assessment-questions-card {
  margin-top: 25px;
  border-radius: 20px;
  background: white;
  padding: 30px 30px;
  min-height: calc(100vh - 300px);

  .question-title {
    font-size: 18px;
    font-weight: 600;
  }

  .options-text {
    font-size: 14px;
    font-weight: 600;
  }
}

.assessment-btn {
  min-height: 50px;
  min-width: 130px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  border-radius: 10px;

  &.radio-btn {
    text-transform: none;
    background: #ffffff;
    border: 1px solid #dcdcdc;
    margin: 0 10px;

    &.selected {
      border: none;
      background: #dcf6ff;
    }
  }

  &.primary {
    background: #08aeea;
    color: #ffffff;
    border: none;
  }

  &.outlined {
    border: 2px solid;
    background: #ffffff;
    color: #08aeea;
  }

  &:disabled {
    opacity: 0.6;
  }
}

.options {
  .option-checkbox {
    input {
      accent-color: #069fd7;
      color-scheme: light;
      cursor: pointer;
      height: 16px;
      width: 16px;
      border: 1px solid #08aeea;

      &::before {
        color: #08aeea;
        background: #08aeea;
      }
    }
  }
  .option-group {
    //justify-content: space-between;
    .option-group-text {
      font-size: 16px;
      font-weight: 600;
    }
    .radio-option {
      cursor: pointer;
      padding: 0 20px;
      display: flex;
      align-items: center;
      height: 60px;
      border: 1px solid #dcdcdc;
      border-radius: 10px;
      font-weight: 500;

      &.selected {
        border: 1px solid #dcf6ff;
        height: 60px;
        background: #dcf6ff;
      }

      input[type='radio'] {
        height: 22px;
        width: 22px;
        accent-color: #069fd7 !important;
        color-scheme: light !important;
      }
    }
  }
  .option-group {
    //justify-content: space-between;
    &.flex {
      display: flex;

      label .radio-option {
        margin-left: 10px;
        margin-right: 10px;
      }
      label:first-child .radio-option {
        margin-left: 0 !important;
      }
      label:last-child .radio-option {
        margin-right: 0 !important;
      }
    }
    .option-group-text {
      font-size: 16px;
      font-weight: 600;
    }
    .radio-option {
      cursor: pointer;
      padding: 0 20px;
      display: flex;
      align-items: center;
      height: 60px;
      border: 1px solid #dcdcdc;
      border-radius: 10px;

      &.selected {
        border: 1px solid #dcf6ff;
        height: 60px;
        background: #dcf6ff;
      }

      .MuiRadio-root {
        padding: 0;
        color: #08aeea !important;
      }

      input[type='radio'] {
        height: 22px;
        width: 22px;
        accent-color: #069fd7 !important;
        color-scheme: light !important;
      }
    }
  }
}
.input-symbol {
  position: absolute;
  margin: 13px 15px;
}
.option-input {
  font-size: 14px;
  font-weight: 500;
  height: 50px;
  width: 440px;
  padding-left: 35px;
}

.check-box-option {
  cursor: pointer;
  height: 130px;
  width: 130px;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  margin-right: 34px;

  label {
    padding: 3px 10px;
    width: 100%;
    height: 130px;
    cursor: pointer;
  }
  .MuiCheckbox-root {
    color: #08aeea !important;
    padding: 0;
    margin-top: 2px;
  }

  .option-icon {
    margin: 0 auto;
    height: 50px;
    width: 50px;
    background: #f5f7f9;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .option-text {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
  }
}
.MuiInputBase-root .MuiSelect-select {
  font-family: 'Montserrat', sans-serif !important;
}
.MuiMenu-paper {
  max-height: 350px !important;
  max-width: 270px;
}

.goal-menu {
  ul.MuiList-root {
    max-width: 370px;
    padding: 8px 12px !important;

    .MuiMenuItem-root {
      border-radius: 5px;
      color: #96a0b5;
      font-size: 12px !important;
      margin: 2px 0;

      &.Mui-selected {
        background: #dcf6ff;
        color: #262e4a;
      }

      &:hover {
        background: #dcf6ff;
        color: #262e4a;
      }
    }
  }
}

.year-menu {
  ul {
    display: flex;
    max-width: 270px !important;
    flex-wrap: wrap;
    padding: 8px 12px;

    .MuiMenuItem-root {
      min-height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      //padding: 11px;
      height: 50px;
      width: 52px;
      border-radius: 10px;
      background: #f5f7f9;
      color: #898989;
      margin: 5px !important;
      font-size: 12px;
      font-weight: 500;
      &.Mui-selected {
        background: #dcf6ff;
        color: #262e4a;
      }
      &:hover {
        background: #dcf6ff;
        color: #262e4a;
      }
    }
  }
}
.multi-options {

  .mui-placeholder {
    opacity: 0.6;
  }
  font-family: 'Montserrat', sans-serif !important;
  input {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 14px;
    font-weight: 500;
  }
  .option-select {
    border-radius: 10px;
    background: #f5f7f9;
    border: none;
    height: 50px;
    .MuiSelect-select {
      font-size: 14px;
      font-weight: 500;
      border: none;
    }
    fieldset {
      border: none;
    }
  }
  .multi-option-input {
    font-size: 14px;
    font-weight: 500;
    height: 50px;
    width: 370px;
    padding-left: 35px;
    border-radius: 10px;
    border: none;
    background: #f5f7f9;
  }

  .other-goal {
    display: flex;
    input {
      background: #f5f7f9 !important;
      border: 1px solid #f5f7f9 !important;
      padding-right: 40px;

    }
    &.other-editing {
      input {
        background: #ffffff !important;
        border: 1px solid rgba(153, 153, 153, 0.4) !important;
      }
    }
    div {
      cursor: pointer;
      height: 50px;
      display: flex;
      align-items: center;
      //position: absolute;
      margin-left: -50px;
      margin-right: 15px;

      svg {
        color: #08aeea;
        height: 18px;
        width: 18px;
      }
    }
  }

  .option-add-icon {
    svg {
      height: 25px;
      width: 25px;
      cursor: pointer;
      color: #08aeea;
    }
  }

  .option-remove-icon {
    svg {
      height: 25px;
      width: 25px;
      cursor: pointer;
      color: #ff6d6a;
    }
  }
  //.option-rows {
  //  margin-top: ;
  //}
  .option-row {
    margin-top: 20px;
  }
  .goal-input {
    font-family: 'Montserrat', sans-serif !important;
    height: 50px;
    width: 370px;
  }
  .currency-input {
    font-family: 'Montserrat', sans-serif !important;
    padding-left: 35px;
    height: 50px;
    width: 370px;
  }
  .year-input {
    font-family: 'Montserrat', sans-serif !important;
    height: 50px;
    width: 270px;
  }
}

.option-div {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  width: 85%;

  .option-div-sub {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;

    .option-circle {
      margin-right: 12px;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      justify-content: center;
      align-items: center;
      display: flex;

      &.orange {
        background: #FFF9E6;
        color: #F1944C;
      }

      &.purple {
        background: #ECEDFD;
        color: #494BEF;
      }

      &.red {
        background: #FFF0F0;
        color: #ED413B;
      }
    }
  }
}
.speedometer {
  .current-value {
    display: none;
  }
}
.assessment-result {
  .result-card-1 {
    background: #FFFFFF;
    min-height: 280px;
    border-radius: 20px;
  }
  .result-card {
    background: #FFFFFF;
    min-height: 280px;
    max-height: 280px;
    border-radius: 20px;

    .speedometer-chart {
      div {
        margin-top: 40px;

        .speedometer {
          text {
            font-weight: 500 !important;
            white-space: pre-wrap;
            word-break: break-word;
            width: 100px;
            max-width: 100px;
          }
        }
      }
      .start-value {
        font-size: 16px;
        position: absolute;
        top: 235px;
        left: calc(50% - 159px);
        font-weight: 600;
      }
      .end-value {
        font-size: 16px;
        position: absolute;
        top: 235px;
        left: calc(50% + 122px);
        font-weight: 600;
      }
    }
  }

  .user-name-title {
    font-size: 18px;
    font-weight: 600;
  }

  .result-text-sub {
    margin-top: 10px;
    color: #96A0B5;
    font-size: 16px;
    font-weight: 400;
  }

  .result-date {
    margin-top: 5px;
    color: #00CCA4;
    font-size: 16px;
    font-weight: 500;
  }
  .observation {
    font-size: 16px;
    font-weight: 500;
    color: #262E4A;
  }
  .retake-assessment-btn {
    width: 270px;
    height: 50px;
    background: #08AEEA;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    border: none;
    border-radius: 10px;
  }
}

@media only screen and (min-width:320px) and (max-width:992px) {
  .assessment-questions-card {
    padding: 20px;

    .option-group {
      text-align: center;
      display: block;
      //width: 50%;

      .option-group-text {
        margin: auto;
      }

      .assessment-btn {
        margin-top: 10px;
      }

      &.type-2 {
        justify-content: space-around;
      }

      .check-box-option {
        margin: 0;
        height: 150px;
        width: 150px;

        .option-icon {
          margin-top: 10px;
        }
      }

      .radio-option {
        height: auto !important;
        text-align: left;
        padding: 15px;
      }

      &.flex {
        display: block;

        .radio-option {
          margin: 0 !important;
        }
      }

      &.type-6 {
        .option-div {
          padding: 0 10px !important;
        }
        .option-div-sub {
          margin: 15px 0;

          .MuiRadio-root {
            margin-top: -190px;
          }
        }
      }
    }

    .option-input {
      width: 100%;
    }

    .multi-options {
      display: block !important;
      .year-select {
        width: 150px !important;
      }
      .year-input {
        width: 125px;
      }
      .multi-option-input {
        width: 90%;
        margin-right: 0;
      }
      .currency-input {
        width: 90%;
        margin-right: 0;
      }
    }
  }
}
@media (min-width: 768px) {
  .w-md-100 {
    width:100% !important;
  }
}
