.DonutPieChart {
  $base: &;
  &__label {
    color: #898989;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.24px;
  }
  &__value {
    color: #464646;
    line-height: 30px;
    letter-spacing: -0.32px;
  }

  &__chart {
    width: 205px;
    height: 205px;
  }

  &__vertical {
    flex-direction: column !important;

    #{$base}__legend {
      display: flex;
      width: 100%;
      padding-bottom: 16px;
      padding-right: 0 !important;
    }

    #{$base}__legendItem {
      justify-content: center;
      flex-grow: 1;
    }
  }

  &__portfolio {
    #{$base}__chart {
      width: 160px;
      height: 160px;
    }
  }

}
