.PureDropdown {
  &__trigger {
    cursor: pointer;
    color: #08aeea;
    font-size: 14px;
    font-weight: 600;
    transition: opacity 150ms;

    &:hover {
      opacity: 0.5;
    }
  }

  &__menu {
    border: none !important;
    transform: translate3d(0, 0, 0) !important;
    left: 0 !important;
    top: 24px !important;
    width: auto !important;
    min-width: 100px;
  }

  &__item {
    font-size: 14px;
  }
}
