.top_categories_list {
  margin: 15px 0;
  display: flex;
  overflow: auto;
  padding-bottom: 10px;

  .top_category_item {
    height: 138px;
    min-width: 138px;
    width: 100%;
    max-width: 148px;
    border-radius: 20px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    margin: 0 10px;
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    .top_category_icon {
      height: 50px;
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      margin: 30px auto 0 auto;
    }

    .top_category_name {
      font-size: 12px;
      font-weight: bold;
      margin: 10px auto;
    }
  }

}
