.wrapper-status {
  width: 100%;
  height: 105vh;
}

.bank-text {
  text-align: center;
  margin-top: 1rem;
}

.status-card {
  height: 311px;
  max-width: 50%;
  width: 100%;
  border: 2px solid #d5d5d5;
  border-radius: 6px;
  overflow-y: auto;
  cursor: pointer;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.icon-img {
  height: 45%;
  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2rem;
}

.status-card-title-text {
  text-align: center;
  margin-top: 1.2rem;
}

.status-card-subtitle-text {
  text-align: center;
  font-size: 12px;
  margin-left: 3rem;
  margin-right: 3rem;
  margin-top: 1rem;
  font-weight: 600;
}

.bank-details-card {
  height: 180px;
  max-width: 50%;
  width: 100%;
  border: 2px solid #d5d5d5;
  border-radius: 6px;
  overflow-y: auto;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-top: 2rem;
  margin-right: auto;
}
.divider {
  height: 2px;
  max-width: 92%;
  width: 100%;
  background-color: #d5d5d5;
  margin-top: 0.2rem;
  margin-left: auto;
  margin-right: auto;
}

.bank-details-text-wrapper {
  height: 40px;
  max-width: 92%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  display: flex;
  gap: 3rem;
}

.bank-details-subtitles-wrapper {
  height: 40px;
  max-width: 92%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  display: flex;
  gap: 3rem;
}

.bank-details-title {
  font-size: 12px;
  font-weight: bold;
}

.bank-details-subtitle {
  font-size: 12px;
  font-weight: bold;
}

.continue-btn-bank-status {
  background-color: #08aeea;
  height: 45px;
  width: 27%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  cursor: pointer;
}

.bank-details-title-text {
  font-size: 12px;
  color: #8a8585;
  font-weight: bold;
}

@media screen and (max-width: 480px) {
  .bank-details-card {
    max-width: 90%;
  }
  .status-card {
    max-width: 90%;
    height: 236px;
  }

  .status-card-subtitle-text {
    font-size: 10px;
  }

  .icon-img {
    height: 25%;
    padding-top: 0.2rem;
  }

  .status-card-title-text {
    margin-top: 1rem;
  }

  .continue-btn-bank-status {
    width: 70%;
  }
}
