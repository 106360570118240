.modal-class {
  height: 370px;
  border-radius: 16px;
}

.subtitle-text {
  font-size: 14px;
  color: #898989;
  font-weight: 400;
  margin-bottom: 0 !important;
}

.subtitle-text-wrapper {
  display: block;
}

.time-text {
  color: #ff6d6a;
  font-size: 13px;
  margin-top: 30px;
}

.refresh-icons {
  height: 12px;
  width: 12px;
  margin-left: 10px;
  margin-bottom: 4px;
  cursor: pointer;
}

.loader-wrapper {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 15px;
}

.modal-content {
  border-radius: 14px !important;
}

.close-modal-icon {
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
}
