.UploadCAS {
  $base: &;
  $break-point: 767px;

  &__tile {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding: 2rem 1rem;
    border-radius: inherit;
    text-align: center;

    &_wrapper {
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 2.1rem;

      margin-block: 1rem;

      @media (max-width: $break-point) {
        width: 100%;
      }
    }

  }

  &__heading {
    font-size: 0.875rem;
  }

  &__input_wrapper {
    width: 100%;
    // margin-block: 1.2rem;
  }

  &__input_label {
    height: 7rem;
    width: 100%;
    cursor: pointer;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    background-color: #f5f7f9;
    padding: 1.5rem;
    border: 2px dashed #262e4a;
    border-radius: 10px;
    opacity: 1;
    box-shadow: 0px 48px 35px -48px rgba(0, 0, 0, 0.1);

    @media (max-width: $break-point) {
      height: 8rem;
    }
  }

  &__input {
    display: none;
  }

  &__upload_file_icon_wrapper {
    color: #96a0b5;
    font-size: 0.875rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    column-gap: 0.875rem;
    font-style: italic;

    @media (max-width: $break-point) {
      font-size: 0.7rem;
      flex-direction: column;
      align-items: center;
      row-gap: 0.6rem;
    }
  }

  &__submit_button {
    border: none;
    border-radius: 6px;
    background: #08aeea;
    color: #fff;
    padding: 12px 20px;
    transition: background-color 150ms;
    width: 60%;

    &:hover {
      color: #fff;
      background-color: darken($color: #08aeea, $amount: 5);
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  &__instruction_model_text {
    cursor: pointer;
    font-size: 0.875rem;
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  &__input_password_wrapper {
    width: 100%;
  }

  &__input_and_icon {
    position: relative;
  }

  &__input_password {
    outline: 2px solid transparent;
    outline-offset: 2px;
    width: 100%;
    border-width: 1px;
    border-radius: 10px;
    font-size: 0.875rem;
    padding: 1rem;
    border-color: transparent;
    background-color: #f5f7f9;
  }

  &__toggle_for_password {
    position: absolute;
    right: 18px;
    top: 25%;
    cursor: pointer;
  }

  &__error_text {
    color: red;
    font-size: 0.8rem;
    display: flex;
  }

  &__format_supported {
    font-size: 0.7rem;
    font-style: italic;
  }
}

.enlarge_asterisks {
  font-size: 2.1rem;
}