.PortfolioMutualFunds {
  $base: &;
  &__grid,
    &__row_grid,
    &__subrow_grid {
    gap: 32px;
    grid-template-columns: repeat(5, 1fr);
    font-size: 14px;
  }

  &__gray {
    line-height: 1.25;
    color: #a7a7a7;
  }

  &__dark {
    color: #202020;
    line-height: normal;
  }

  &__logo {
    width: 40px;
  }

  &__tile {
    padding: 30px 20px 20px;
    border-radius: 14px;
    background-color: #fff;
  }

  &__row {
    border-radius: 14px;
    background-color: #f7f7f7;
    padding: 12px 20px;
  }

  &__green {
    color: #78ca85;
  }

  &__red {
    color: #f67676;
  }

  &__orangeLink {
    color: #f8a544;
    &:hover {
      color: #f8a544 !important;
    }
  }

  &__folio {
    padding: 30px 20px;
  }

  &__folios {
    #{$base}__grid:not(:nth-of-type(1)) {
      margin-top: 12px;
    }
  }
}

.MutualFunds__header {
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  color: #464646;
}
@media screen and (max-width: 768px) {
  .PortfolioMutualFunds {
    $base: &;

    &__tile {
        background-color: #f7f7f7;
      }
    &__row {
      padding: 12px;
      background-color: #fff;
    }

    &__grid {
      display: none;
    }

    &__row_grid,
    &__subrow_grid {
      gap: 12px;
      grid-template-columns: repeat(5, 1fr);
      font-size: 14px;
    }

    &__row_grid {
      grid-template-columns: 1fr;



      &__fund_name {
        color: #202020;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem;
        /* 142.857% */
      }
    }

    &__subrow_grid {
      grid-template-columns: 1fr 1fr max-content;
      margin-block: 0.6rem;
      padding: 20px 15px !important;
      background-color: #f7f7f7 !important;
    }

    &__folio {
      padding: 30px 20px;
      border-radius: 0.6rem !important;

      &__number,
      &__curr_value,
      &__units,
      &__investment {
        flex-direction: column;
        align-items: flex-start !important;
        row-gap: 0.5rem;
      }

      &__number {
        order: 1;
      }

      &__curr_value {
        order: 5;
        grid-column: 2 / 4;
      }

      &__units {
        order: 2;
      }

      &__investment {
        order: 4;
      }

      &__icons {
        order: 3;
        height: fit-content;
        background: #fff;
        padding: 0.5rem;
        border-radius: 0.5rem;
        cursor: pointer;

        &__clicked {
          border: 3px solid #b7b7b7
        }
      }

      &__actions {
        order: 6;
        grid-column: 1 / 4;
        background: #fff;
        justify-content: space-around !important;
        padding-block: 0.5rem;
        border-radius: 0.3rem
      }
    }
  }


}