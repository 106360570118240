.app-v2 {
  .sidebar {
    position: fixed;
    background-color: #FFFFFF;
    width: 250px;
    top: 72px;
    height: 100%;
    box-shadow: 2px 0px 10px #262E4A0D;
    transition: 0.3s;
    animation: ease-in-out;
    animation-duration: .5s;
    z-index: 100;

    .sidebar_profile {
      display: flex;
      align-items: center;
      margin-top: 25px;
      margin-bottom: 25px;
      margin-left: 30px;
      transition: 0.3s;
      animation: ease-in-out;
      animation-duration: .5s;

      .sidebar_profile-img {
        cursor: pointer;
        div{
          height: 40px !important;
          width: 40px !important;
        }
        .sb-avatar {
          height: 40px !important;
          width: 40px !important;

          img {
            height: 40px !important;
            width: 40px !important;
          }
        }
      }

      .sidebar_profile-details {
        margin-left: 10px;
        line-height: normal;

        .sidebar_profile-name {
          white-space: nowrap;
          overflow: hidden;
          width: 150px;
          text-overflow: ellipsis;
          cursor: pointer;
          span {
            font-size: 14px;
            font-weight: bold;
          }
        }

        .sidebar_profile-type {
          span {
            font-weight: 600;
            font-size: 12px;
            color: #96A0B5;
          }
        }
      }
    }

    .horizontal-line {
      border-bottom: 1px solid #96a0b53b;
      margin: 0 14px;
    }

    .sidebar-menu-list {
      margin: 14px;

      .sidebar-menu-item {
        padding: 16px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #96A0B5;
        height: 50px;
        border-radius: 8px;
        font-weight: 500;

        span.menu-title {
          margin-left: 10px;
          white-space: nowrap;
          overflow: hidden;
          margin-bottom: 3px;
        }

        &.link-active {
          color: #262E4A;
          background-color: #DCF6FF;
        }
      }
    }

    &.sidebar_collapsed {
      width: 80px !important;

      .sidebar_profile {
        margin-left: 22px;
      }

      .sidebar_profile-details {
        display: none;
      }

      .sidebar-menu-item {
        span {
          display: none;
        }
      }
    }

  }
}

@media only screen and (min-width:320px) and (max-width:992px) {
  .app-v2 {
    .sidebar {
      width: 90%;
      &.sidebar_collapsed {
        width: 0 !important;
        display: none;
      }
      .sidebar_profile-name {
        white-space: nowrap;
        overflow: hidden;
        width: 100% !important;
        text-overflow: ellipsis;
        cursor: pointer;

      }
    }
  }
}