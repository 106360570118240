.init {
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.bank_mandate {
  color: #000000;
  font-weight: 600;
  font-size: 14px;
  margin-top: 0.7rem;
}

.details_card {
  height: 55%;
  max-width: 35%;
  width: 100%;
  border-radius: 8px;
  border: 2px solid #d5d5d5;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.maximum_debit_limit-text {
  color: #000000;
  font-weight: 600;
  font-size: 14px;
  margin-top: 1.5rem;
  text-align: center;
}

.text-input {
  margin-top: 0.8rem;
  max-width: 45%;
  width: 100%;
  height: 10%;
  border-radius: 4px;
  border: 2px solid #d5d5d5;
  text-align: center;
  color: #000000;
}

.divider-autopay {
  height: 1px;
  max-width: 82%;
  width: 100%;
  background-color: #d5d5d5;
  margin-top: 2rem;
}

.payment-wrapper {
  height: 31%;
  max-width: 82%;
  width: 100%;
  margin-top: 2rem;
}

.btn-box-one {
  width: 100%;
  height: 33%;
  display: flex;
  justify-content: space-between;
}

.btn-box-two {
  width: 100%;
  height: 33%;
  display: flex;
  gap: 1em;
  justify-content: center;
  margin-top: 1em;
}

.btn-box-price {
  width: 30%;
  height: 90%;
  background-color: #d5d5d5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  cursor: pointer;
  border-radius: 4px;
}

.btn-box-price:hover {
  transition: 0.2s ease-in;
  background-color: #5ade53;
  color: #ffffff;
}

.btn-box-price-selected {
  width: 30%;
  height: 90%;
  transition: 0.2s ease-in;
  background-color: #5ade53;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  cursor: pointer;
  border-radius: 4px;
  color: #ffffff;
}

.paragragh-message-wrapper {
  width: 100%;
  height: 20%;
  position: relative;
}

.message-text {
  height: 18px;
  width: 18px;
  position: absolute;
  top: 26%;
  left: 90%;
}

.react-tooltip-lite {
  background: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  color: #000000;
  border-radius: 4px;
}

.react-tooltip-lite-arrow {
  border-color: #ffffff;
}

.content-text {
  font-weight: 400;
  font-size: 10px;
}

.continue-btn-autolimit {
  background-color: #08aeea;
  height: 6%;
  width: 22%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-weight: 600;
  margin-top: 1.7rem;
  cursor: pointer;
}

.error-message {
  font-size: 10px;
  font-weight: 600;
  color: red;
  margin-top: 0.8em;
  margin-right: 0.8em;
}

@media screen and (max-width: 480px) {
  .details_card {
    height: 42%;
    max-width: 94%;
    width: 100%;
    border-radius: 8px;
    border: 2px solid #d5d5d5;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .message-text {
    height: 18px;
    width: 18px;
    position: absolute;
    top: 0;
    right: 10px;
  }
  .text-input {
    max-width: 90%;
  }

  .divider-autopay {
    height: 1px;
    max-width: 97%;
    width: 100%;
    background-color: #d5d5d5;
    margin-top: 2rem;
  }

  .continue-btn-autolimit {
    background-color: #08aeea;
    height: 6%;
    width: 79%;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-weight: 600;
    margin-top: 1.7rem;
    cursor: pointer;
  }
}
