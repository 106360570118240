.DownloadCASModal {
    $base: &;


    &__header {
        margin: 0 2.2rem;
        padding: 1.2rem 0;
        font-weight: 600;
    }

    &__link {
        font-style: italic;
        text-decoration: underline !important;
    }
}


.timeline {
    width: 85%;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 1rem 2rem;
    border-left: 3px dotted #F5F5F5;
    font-size: 1.125rem;
}

.timeline-item {
    display: flex;
    gap: 24px;
    align-items: flex-start;

    &+* {
        margin-top: 24px;
    }

    &+.extra-space {
        margin-top: 48px;
    }
}

.timeline-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    flex-shrink: 0;
    overflow: hidden;
    box-shadow: 0 0 0 6px #F5F5F5;
    margin-left: -13px;
    background: #F5F5F5;

    svg {
        width: 20px;
        height: 20px;
    }

    &.faded-icon {
        background-color: var(--c-grey-100);
        color: var(--c-grey-400);
    }

    &.filled-icon {
        background-color: var(--c-blue-500);
        color: #fff;
    }
}


.timeline-item-heading {
    font-size: 0.875rem;
}

.timeline-item-description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 1rem;
    margin-top: 3px;
}

.timeline-sub-item {
    display: flex;
}

.timeline-sub-item-key {
    font-size: 0.875rem;
    color: #96A0B5;
    font-weight: 600;
}

.timeline-sub-item-value {
    font-size: 0.875rem;
    font-weight: 600;
}

.two-col-grid-layout {
    display: grid;
    grid-template-columns: 30% 1fr;
    row-gap: 1rem;
}