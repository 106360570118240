.Usergroups {
    $base: &;
    $font-black: #202020;
    $font-grey: #BCBCBC; // Heading text
    $background-color-white: #FFF;

    color: $font-black;

    // Define the variable for better control & maintainability
    // 1. font size
    // 2. font color. e.g., primary, secondary
    // For Search input 
    &_search_container {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        min-width: 12rem;
        width: 100%;
        border-radius: 0.375rem;
        border: 1px solid #DCDCDC;
        background: $background-color-white;

        >img {
            margin: 0.75rem 0.94rem;
        }

        >input {
            height: 100%;
            outline: none;
            border: none;
            border-radius: 0.375rem;
            width: 100%;
        }
    }

    &_button {

        &_container {
            display: flex;
            flex-direction: row-reverse;
            margin-block: 1rem;
        }
    } 

    &_header {
        display: grid;
        grid-template-columns: 1fr auto;
    }

    &_header_title {
        font-size: 1.625rem;
        font-weight: 600;

    }

    &_header_button {
        outline: none;
        border: none;
    }

    &_horizontal_divider {
        display: flex;
        justify-content: flex-start;
        border-bottom: 1.2px solid rgb(220, 220, 220);
        margin-top: 1.8rem;
    }

        &_vertical_divider {
            height: 100%;
            margin-inline: 0.2rem;
            border-right: 1.2px solid rgb(220, 220, 220);
        }
    &_table_container {
        padding-inline: 1.26rem;
        padding: 1.26rem;
        background: $background-color-white;
        border-radius: 0.75rem;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
        min-width: 360px;
    }

    // ************* Grid's style *************
    &_table {
        display: flex;
        flex-direction: column;

        &_header {

            display: grid;
            grid-template-columns: 2fr 1fr;
            padding-bottom: 0.8rem;
            font-size: 0.875rem;
            color: $font-grey;
        }

        &_edit_button {
            outline: none;
            border: none;
            width: fit-content;
            background-color: $background-color-white;
            color: #007bff;
        }

        &_edit_button:hover {
            color: #0056b3;
        }
        &_rows {
            display: flex;
            flex-direction: column;
        }

        &_row {
            display: grid;
            grid-template-columns: 2fr 1fr;
            border-top: 1px solid #F5F5F5;
            padding-block: 0.8rem;
            font-size: 0.875rem;

            &>div:nth-child(-n+1) {
                    align-self: self-start;
                }

            &_actions_button {
                outline: none;
                border: none;
                width: fit-content;
            }
        }

        &_no_data {
            margin-block: 1rem;
            color: $font-black;
        }
    }
}