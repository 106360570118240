.mandate-card {
    width: 700px;
    border-radius: 8px;
    border: 2px solid #c9c9c9;
    padding: 16px;
    margin: 14px auto 14px auto;
    height: auto !important;

    &.selected {
         border: 2px solid #00b2e6;
    }

    .sip-radio {
        input {
            height: 28px;
            width: 28px;
        }
    }

    .switch {
        margin: 4px 8px;
        height: 18px !important;
        width: 38px !important;

        span {
            left: 1px !important;
            height: 14px !important;
            width: 14px !important;
            top: 1px !important;
        }

        &.checked {
            span {
                left: 21px !important;
            }
        }
    }
}

.sip-next {
    width: 160px;
}
.cursor-pointer {
    cursor: pointer;
}
.flex-class {
  display: block;
}

@media screen and (max-width: 480px) {
  .mandate-card {
    width: 300px;
  }
  .flex-class {
    display: block;
  }

  .center-radio-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
