
.border-none-top{
    border-radius: 0px 0px 10px 10px !important;
    box-shadow: none !important;

}
.border-none-bottom{
    border-radius: 10px 10px 0px 0px !important;
    box-shadow: none !important;

}
@media screen and (max-width: 480px) {
    .list-text{
        font-size:13px;
    }

}
