.external-fund-card {
  margin: 20px 0 0 0;
  border-radius: 20px;
  display: flex;
  height: 100%;
  max-width: 1500px;

  .external-fund-form {
    background: #FFFFFF;
    height: 100%;
    width: 100%;
    align-items: center;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    padding: 30px 80px;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    input[type=checkbox] {
      margin-top: 7px;
    }

    input[type=checkbox]:checked {
      accent-color: #262E4A;
      color: #262E4A;
    }

    input[type=checkbox]:checked+label {
      accent-color: #262E4A;
      color: #262E4A;
    }

    .rbt {
      .rbt-input {
        padding-right: 20px;
      }

      .toggle-button {
        padding-right: 0;
        font-size: 22px;
        padding-bottom: 8px;
        color: black;

        &.open {
          padding-top: 8px;
          padding-bottom: 0 !important;
        }
      }
    }

    .external-to-note {
      font-size: 10px;
      margin-left: 14px;
      color: #262E4A;
      font-weight: 500;
      //max-width: 400px;
    }

    .external-fund-radio {
      label {
        margin-bottom: 0;
        margin-top: 4px;
      }
    }

    .external-fund-btn {
      height: 50px;
      width: 250px;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .external-fund-info {
    background: #ECEDFD;
    width: 100%;
    min-height: 60vh;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .external-fund-dynamic-tile {
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
  }

  .external-fund-info-title {
    font-size: 18px;
    color: #262E4A;
    font-weight: bold;
    max-width: 300px;
    margin: 20px auto;
  }

  .external-fund-info-description {
    font-size: 14px;
    color: #262E4A;
    font-weight: normal;
    max-width: 380px;
    margin-top: 20px;
  }

  .external-fund-info-description.mobile-input-field {
    font-size: 14px;
    color: #262E4A;
    font-weight: normal;
    max-width: 380px;
    margin-top: 20px;
    text-align: left !important;
  }

  .external-fund-info-description.mobile-input-field input#mobile-email-value {
    background-color: #F5F7F9;
  }

  button.extenal-fund-btn {
    width: 186px !important;
    margin-top: 10px;
  }

  @media (max-width: 767px) {


    .external-fund-info {
      border-top-right-radius: 20px !important;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .external-fund-dynamic-tile {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }

  }
}