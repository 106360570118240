.PortfolioDepositsCards {
    $base: &;

    &__grid,
    &__row_grid {
        gap: 32px;
        grid-template-columns: 2fr repeat(4, 1fr);
        font-size: 14px;
    }

    &__itemGrid {
        gap: 24px;
        grid-template-columns: 1.1fr 1.1fr 1.2fr 1.4fr 0.8fr;
        font-size: 14px;
    }

    &__isser_detail {
        grid-column-start: 1;
        grid-column-end: 3;
    }

    &__gray {
        line-height: 1.25;
        color: #a7a7a7;
    }

    &__dark {
        color: #202020;
        line-height: normal;
    }

    &__logo {
        width: 40px;
        height: 40px;
        object-fit: contain;
    }

    &__tile {
        padding: 30px 20px 20px;
        border-radius: 14px;
    }

    &__row {
        border-radius: 14px;
        background-color: #fff;
        padding: 12px 20px;
        margin-bottom: 16px;
    }

    &__item {
        padding: 30px 20px;
    }

    &__folios {
        #{$base}__itemGrid:not(:nth-of-type(1)) {
            margin-top: 12px;
        }
    }
}

$breakpoint-mobile-500: 500px;
$breakpoint-mobile-992: 992px;



@media screen and (max-width: $breakpoint-mobile-992) {
    .PortfolioDepositsCards {
        $base: &;

        &__grid {
            display: none;
        }

        &__row_grid {
            grid-template-columns: repeat(2, 1fr);
        }

    }
}

@media screen and (min-width: $breakpoint-mobile-500) {
    .PortfolioDepositsCards {
        $base: &;

        &__row_grid {
            grid-template-columns: repeat(3, 1fr);


        }

        &__row_grid>div {
            flex-wrap: nowrap;
        }

        &__isser_detail {
            grid-column-start: 1;
            grid-column-end: 4;
        }

    }
}