.custom-tooltip {
  position: relative;
  display: inline-block;
}

.custom-tooltip .custom-tooltip-text {
  visibility: hidden;
  width: 220px;
  background-color: #e5e5e5;
  color: black;
  text-align: center;
  border-radius: 1px;
  padding: 2px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -110px;
  opacity: 0;
  transition: opacity 0.7s;
  border-radius: 12px;
}

.custom-tooltip .custom-tooltip-text::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #e5e5e5 transparent transparent transparent;
}

.custom-tooltip:hover .custom-tooltip-text {
  visibility: visible;
  opacity: 1;
}

.info-image {
  height: 18px;
  width: 18px;
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .custom-tooltip .custom-tooltip-text {
    visibility: hidden;
    width: 150px;
    background-color: #e5e5e5;
    color: black;
    text-align: center;
    border-radius: 1px;
    padding: 2px 0;
    position: absolute;
    z-index: 1;
    bottom: 20%;
    margin-left: -220px;
    opacity: 0;
    transition: opacity 0.7s;
    border-radius: 12px;
    left: 50%;
    font-size: 12px;
  }
}
