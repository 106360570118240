.invest-funds-container {
  .row {
    margin: 0;
  }


  .invest-fund-container {
    background: #fff;

    .new-input-group {
      position: relative;

      .search-input.icon {
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 9;
        width: 20px;
      }
    }
  }

  .invest-fund-list-container {
    border-top: 1px solid #f0f0f0;
  }

  // Filter section styles
  .border.rounded {
    background: #fff;
  }
 
  .category-container,
  .nfo-container,
  .fund-size-container,
  .risk-container,
  .sub-category-container {
    .accordion {
      border: none;
    }
  }

  .fund-accordinan {
    max-height: 300px;
    overflow-y: auto;
  }
}

// Mobile styles
.bg-light-grey {
  background: #f8f9fa;

  .w-90 {
    width: 90%;
  }

  .colored-box {
    background: #fff;
    min-height: calc(100vh - 180px);
  }

  .bg-light-blue {
    background: rgba(0, 123, 255, 0.1);
  }

  .br-light {
    border-radius: 20px;
  }
}

// Responsive styles
@media (max-width: 768px) {
  .invest-funds-container {
    .col-sm-3 {
      margin-bottom: 1rem;
    }
  }
}

@media (max-width: 480px) {
  .invest-funds-container {
    padding: 0 15px;
  }

  .new-input-group {
    .form-control {
      height: 40px;
    }
  }

  .colored-box {
    .btn {
      font-size: 14px;
      padding: 6px 15px;
    }
  }
}

.new-input-group {
    .search-input.icon {
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 3;
      width: 16px;
      height: 16px;
  
      @media (max-width: 480px) {
        width: 14px;
        height: 14px;
        left: 10px;
      }
    }
  
    .form-control {
      padding-left: 40px !important;
  
      @media (max-width: 480px) {
        padding-left: 30px !important;
      }
    }
  }

  .dropdown-list-wrapper {
    .sort-by-label {
      display: inline-block;
      margin-bottom: 8px;
    }
  
    @media (max-width: 480px) {
      .sort-by-label {
        display: block;
        margin-bottom: 8px;
        font-weight: 500;
      }
    }
  }