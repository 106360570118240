
body.v2 {
  background-color: #F5F7F9 !important;
  font-family: 'Montserrat', sans-serif !important;
}
.app-container {
  display: block;
  width: 100%;
  height: calc(100vh - 72px);
  position: absolute;
  margin-top: 72px;
  padding-left: 255px;
  padding-right: 10px;
  transition: 0.3s;
  animation: ease-in-out;
  animation-duration: .5s;

  &.sidebar_collapsed {
    padding-left: 85px;
  }

  div.container-fluid {
    padding-top: 20px;
  }
}

::-webkit-scrollbar {
  width: 4px !important;
  height: 8px !important;
  cursor: pointer !important;
  &:hover {
    height: 14px !important;
  }
}

//My portfolio Page item
.my-portfolio-item-div {
  width: 300px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #CACACA;
  border-radius: 10px;
  width: 4px;
  height: 8px;
  &:hover {
    height: 14px !important;
  }
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #CACACA;
  border-radius: 10px;
  width: 4px;
  height: 8px;
  &:hover {
    height: 14px !important;
  }
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #CACACA;
  height: 14px !important;
}

@media only screen and (min-width:320px) and (max-width:992px) {
  .app-container {
    padding: 0 !important;
    overflow-x: hidden;
  }

  ::-webkit-scrollbar {
    width: 0 !important;
  }
}

//Add the Css for mobile view Onboarding sdetail form
@media only screen and (max-width:765px) {
hr.custom-hrtag-onboarding-detail{
  margin-top: 1.5rem !important;
}
}

// Layout Styles
@import "./layout/header";
@import "./layout/sidebar";

//Component Styles
@import "./components/top-category";
@import "./components/top-picks";
@import "./components/my-portfolio";
@import "./components/quick-links";
@import "./components/switch-funds";
@import "./components/external-funds";
@import "./components/risk-assessment";
@import "./components/buttons";
@import "./form/forms";

@import "common";
