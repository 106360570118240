.app-v2 {
  .form-group {
    label {
      font-size: 12px;
      color: #96A0B5;
      font-weight: 500;
    }
    input, select {
      font-size: 14px;
    }
    input[type='text'] {
      //height: 50px;
      //border-radius: 10px;
    }
    input[type='number'] {
      //height: 50px;
      //border-radius: 10px;
    }
    select {
      //height: 50px;
      //border-radius: 10px;
    }
    .invalid-feedback {
      margin-left: 15px;
    }
    input[type='radio'] {
      height: 15px;
      width: 15px;
      &:after {
        border: 2px;
      }
    }
    input[type=radio]:checked {
      accent-color: #262E4A;
      color: #262E4A;
    }
    input[type=radio]:checked + label{
      accent-color: #262E4A;
      color: #262E4A;
    }
  }
  .toggle-button {
    background: transparent;
    border: 0;
    bottom: 0;
    padding: 0 12px;
    position: absolute;
    right: 0;
    top: 0;
    color: #A7A7A7;
  }
}