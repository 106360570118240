.app-v2 {
  .header {
    width: 100%;
    position: fixed;
    top: 0;
    height: 72px;
    z-index: 101;
    background-color: #FFFFFF !important;

    .header_content {
      display: flex;
      padding: 10px;
      height: 100%;
      align-items: center;

      .header_menu_toggle {
        //padding: 20px;
        margin-left: 24px;

        img {
          cursor: pointer;
        }
      }

      .header_app_icon {
        margin-left: 22px;
      }

      .header-other-menu {
        display: flex;
        //margin-left: auto;
        margin-right: 10px;

        .header-icon {
          height: 42px;
          width: 42px;
          background-color: #F5F7F9;
          color: #96A0B5;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 10px 0 10px;
          cursor: pointer;
        }

        .header-icon-profile {
          height: 42px;
          width: 42px;
          background-color: #F5F7F9;
          color: #08AEEA;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 10px 0 10px;
          cursor: pointer;
          svg {
            height: 34px;
            width: 34px;
          }
        }

        .profile-menu {
          .MuiMenuItem-root {
            margin: 2px 0;
            padding: 10px 15px;
            font-family: 'Montserrat', sans-serif;
            svg {
              color: #96A0B5;
              margin-right: 15px;
              height: 22px;
              width: 22px;
            }
          }
        }
      }

      .header-search-bar {
        margin-left: auto;
        margin-right: 20px;

        div, span, input {
          border: none !important;
          background-color: #F5F7F9 !important;
          height: 36px;
        }

        .input-group-text {
          margin-left: 15px;
        }

        .input-group {
          width: 300px;
          height: 36px;
        }
      }
    }
  }
}

@media only screen and (min-width:320px) and (max-width:992px) {
  .header-search-bar {
    display: none !important;
  }
  .header-other-menu {
    align-items: center;
    .header-icon-profile {
      position: absolute;
      right: 0;
    }
  }


  .header_content {
    justify-content: center;
    width: 100vw !important;

    .header_menu_toggle {
      position: absolute;
      left: 0;
    }
    .header_app_icon {
      margin-left: 0 !important;
      img {
        height: 48px;
      }
    }
  }
}