.buttonIn {
  position: relative;
  input {
    max-width: 20rem !important;
    border: none;
    border-bottom: 1px solid;
    border-radius: 0%;
    padding-left: 0 !important;
  }
  .clear-btn {
    position: absolute;
    top: 0;
    right: 0px;
    top: 5px;
    transform: translateX(2px);
    padding: 0 0.5rem;
  }
}
