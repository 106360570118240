.my-portfolio-card {
  background: white;
  height: 270px;
  border-radius: 20px;
  padding: 30px;

  .portfolio-item {
    display: flex;
    margin-bottom: 24px;

    .portfolio-item-icon {
      height: 55px;
      width: 55px;
      background: #F5F7F9;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
    }

    .portfolio-item-details {
      margin: auto 0 auto 18px;


      .portfolio-label {
        font-size: 12px;
        font-weight: normal;
        color: #96A0B5;
      }
      .portfolio-amount {
        font-size: 20px;
        font-weight: bold;
        color: #262E4A;

        span {
          color: #1FC758;
          
        }
        .c-red{
          color: #e42c2c !important
       }
      }
    }

    &:last-child {
      border: none !important;
    }
  }
}

.my-portfolio-list-card {
  background: white;
  min-height: 270px;
  border-radius: 20px;
  padding: 10px 15px;
  margin-top: 20px;

  .portfolio-header {
    margin: 0 15px;
    border-bottom: 1px solid #F5F5F5;
    padding: 5px 15px;
    color: #96A0B5;
    font-size: 14px;
    font-weight: 500;

    .scheme-name {
      width: 40%;
    }
    .value-col {
      width: 14%;
    }
    .action-col {
      width: 18%;
    }
  }
  .portfolio-item {
    margin: 10px 15px;
    padding: 5px 15px;
    color: #96A0B5;
    font-size: 14px;
    font-weight: 500;
    .scheme-name {
      width: 40%;
    }
    .value-col {
      width: 14%;
      font-size: 14px;
      font-weight: 600;
      color: #262E4A;
      margin-top: auto;
    }
    .action-col {
      width: 18%;

      .folio-action-item {
        font-size: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 10px 10px 10px;
        cursor: pointer;
        div {
          margin-top: 4px;
          text-align: center;

          &.add-fund {
            color: #00CCA4;
          }
          &.redeem-fund {
            color: #FF6D6A;
          }
          &.switch-fund {
            color: #F1944C;
          }
        }
      }
    }

    .fund-image-div {
      //flex: 0 0 10%;
      max-width: 50px;
    }
    .fund-detail-div {
      //flex: 0 0 90%;
      max-width: 90%;
      padding-left: 12px;
      font-size: 16px;
      color: #262E4A;
      font-weight: 600;

      .folio-items {
        font-size: 14px;
        margin-top: 2px;
        span {
          font-weight: 500;
          color: #96A0B5;
        }
      }

    }

    .fund-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #FFFFFF;
      box-shadow: 0px 3px 6px #00000029;
      padding: 3px;
      border-radius: 5px;
      height: 46px;
      width: 46px;
      img {
        height: 40px;
        width: 40px;
        object-fit: contain;
      }
    }
  }
}

.empty-portfolio-card {
  width: 100%;
  height: calc(100vh - 172px);
  border-radius: 20px;
  background: white;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;

  .empty-card-logo {
    height: 125px;
    width: 125px;
    border-radius: 50%;
    background: #FFF9E6;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .empty-note {
    text-align: center;
  }
}

@media only screen and (min-width:320px) and (max-width:992px) {
  .my-portfolio-card {
    height: 300px;
    margin-top: 15px;
  }
  .portfolio-item {
    margin: 8px;
    padding: 0 0 8px 0 !important;
    color: #96A0B5;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid #F5F5F5;

    .folio-value-mobile {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 8px;
    }
    .folio-items-mobile {
      text-align: center;
      span {
        font-size: 10px;
        font-weight: 400;
        text-align: center;
        margin: auto;
      }

      div.value {
        font-size: 12px;
        font-weight: 500;
        color: #262E4A;
        margin: 0 4px;
        div {
          text-align: left;
        }
      }

    }

    .folio-action-item {
      display: flex !important;
      flex-direction: row !important;
      div {
        margin-left: 5px;
        margin-top: 0 !important;
        font-size: 10px;
        font-weight: 600;
      }
    }

    .action-col {
      width: 100% !important;
      justify-content: space-around;
    }
  }
}
.return-type-select {
  border: none;
}