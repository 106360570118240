.FDRow {
  
  grid-template-columns: 2.5fr 1.2fr 1.5fr 1.2fr 1.2fr 136px;
  gap: 32px;
  font-size: 0.875rem;
  line-height: 1.25;
  color: #202020;

  &__name {
    gap: 12px;
    font-weight: 600;
  }

  &__logo {
    width: 40px;
    height: 40px;
    img {
      object-fit: contain;
    }
  }

  &__btn {
    border-color: #08aeea !important;
    font-size: 0.75rem;
    line-height: 1.25;
    color: #08aeea !important;
    transition: opacity 150ms;

    &:hover {
      color: #08aeea !important;
      opacity: 0.5;
    }
  }
}

.FDRow__fields_mobile {
  grid-column: 1/3;
  display: grid;
  grid-template-columns: 1fr 1.05fr;
  row-gap: 12px;
  justify-content: space-between;
  margin-left: 53px;
  margin-bottom: 5px;

  span {

    font-size: 0.875rem;
    font-style: normal;
    // font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }

  span:nth-child(even) {
    padding-top: 0.3rem;

  }

  &__heading {
    color: #BCBCBC;
    font-weight: 600;
  }


}

$breakpoint-mobile1: 600px;
$breakpoint-mobile2: 992px;

@media screen and (min-width: $breakpoint-mobile1) {
  .FDRow__fields_mobile {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: $breakpoint-mobile2) {
  .FDRow {
    grid-template-columns: 1fr;
    gap: 12px;

    &__logo {
      min-width: 40px;
    }

    &__btn {
      padding: 0.4rem 0.7rem !important;
      font-weight: 500;
    }
  }



}