.history_table_wrapper {
  margin-top: 1.81rem;
  padding-inline: 1.26rem;
  padding: 1.26rem;
  background: #fff;
  border-radius: 0.75rem;
  width: 100%;
}

.history__table {
  width: 100%;
}

.history__table th {
  padding-block: 1.26rem;
}

.history__table_header {
  color: #bcbcbc;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.history__table_row > td,
.history__table_header {
  padding-right: 0.5rem;
  font-size: 0.875rem;
  padding-left: 10px;
}

.history__table_header:nth-child(n + 4) {
  text-align: center;
}

.history__table_header:nth-child(4) {
  text-align: right;
  padding-right: 1rem !important;
}

.history__table_row {
  border-radius: 6px;
}

.history__table_row > td:first-child {
  padding-left: 0.88rem;
  padding: 10px;
}

.history__table_row > td:nth-child(n + 4),
.amount {
  padding-right: 1rem !important;
  text-align: right !important;
}

.history_table_issuer {
  width: 70%;
  font-weight: bold;
}

.history__table_status {
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.approved {
  color: white;
  color: green;
}

.vkyc_under_review {
  color: black;
  color: #1975d1;
}

.not-started {
  color: white;
  color: gray;
}

.rejected {
  color: white;
  color: red;
}

.pending_vkyc {
  color: black;
  color: orange;
}

@media screen and (max-width: 520px) {
  .history_table_issuer {
    width: 55%;
  }
}
