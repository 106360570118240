.top_picks_card {
  border-radius: 20px;
  background-color: #FFFFFF;
  min-height: 100px;
  width: 100%;
  margin-top: 20px;
  padding: 30px 20px 20px;

  .top_picks_header {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
      gap: 10px;
      padding-bottom: 12px;
      line-height: 1.25;
      color: #a7a7a7;
  
    }
  .top-picks-row {
    display: grid;
      grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    align-items: center;
    height: 100%;
    margin-bottom: 18px;
    padding-top: 15px;
    gap: 10px;
    border-top: 1px solid #F5F5F5;

    .top-picks-fund-detail {
      gap: 12px;

      .top-picks-fund-logo {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        padding: 3px;
        border-radius: 5px;
        margin-right: 10px;

        img {
          height: 40px;
          width: 40px;
          object-fit: contain;
        }
      }

      .top-picks-fund-title {
        cursor: pointer;
        //white-space: ;
        font-size: 14px;
        font-weight: 600;
        //max-width: 30%;
        margin-right: 30px;
        color: #202020;
      }
    }

    .top-picks-fund-recom {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 30px;
      text-align: center;
    }

    .top-picks-fund-rating {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 30px;
      text-align: center;

      .star-ratings {
        white-space: nowrap;
      }

    }
  }
                
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                .invest-button-div {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  justify-self: self-end;
  }

  .label-text-light {
    font-size: 0.875rem;
      font-weight: 600;
    margin-bottom: 5px;
    color: #BCBCBC;
  }

  .label-return-per {
    font-size: 0.875rem;
      font-weight: 500;
    margin-bottom: 5px;
    color: #202020;
    }
    }
    
    .top-picks-values {
      font-size: 0.875rem !important;
      font-weight: 600;
    
      div.value {
        font-size: 0.875rem;
        font-weight: 500;
  }
}
.top_picks__explore_all {
  list-style-type: none;
  cursor: pointer;
  font-size: 12px;
  line-height: 1.25;
  color: #08aeea;
  transition: opacity 150ms;
}

.section_title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media only screen and (min-width:320px) and (max-width:992px) {

  .top_picks_card {
    & ::-webkit-scrollbar {
      width: 2px !important;
    }

                padding: 30px 20px 20px !important;

    .top-picks-row:first-child {
      border: none !important;
    }

    .top-picks-row {

      .top-picks-fund-detail {
        .top-picks-fund-logo {
          cursor: pointer;
          margin-right: 14px !important;
        }

        display: flex;
        width: 100% !important;
                justify-content: space-between !important;

        .top-picks-fund-title {
          cursor: pointer;
          font-size: 0.875rem !important;
          margin: 0 !important;
        }

        .top-picks-fund-rating {
          min-width: 90px !important;
          text-align: center;
          //margin: 0 !important;
          line-height: 0.8;
          margin-right: 0;
          margin-left: auto;

          .label-text-light {
            font-size: 8px !important;
            
          }
        }
      }

      .top-picks-mobile-vals {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 8px;

        .top-picks-values {
          text-align: center;

          span {
            font-size: 0.875rem;
              color: #BCBCBC;
              font-weight: 600;
            margin: 0 4px;
            white-space: nowrap;
          }

          div.value {
            font-size: 0.875rem;
              font-weight: 500;
          }
        }
      }

      .invest-btn-div {
        display: flex;
        align-items: center;
      }
    }
  }
}