.dropdown-list {
  .dropdown-toggle {
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    padding: 0 0.5rem 0.1rem;
    min-height: 25px;
    &::after {
      content: none;
    }
  }
  .dropdown-menu {
    width: 100%;
    max-height: 250px;
    overflow-y: auto;
  }
}
