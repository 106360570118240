.signatureCanvas {
    border: 1px solid black;
    width: 100%;
    min-height: 500px;
  }

.signContainer {
    width: 100%;
    height: 100vh;
    top: 10%;
    left: 10%;
  }

  .sigContainer {
    width: 80%;
    height: 80%;
    margin: 0 auto;
    background-color: #fff;
  }

  .sigPad {
    width: 100%;
    height: 100%;
  }

  .buttons {
    width: 100%;
    height: 30px;
  }

  .sigImage {
    background-size: 200px 50px;
    width: 200px;
    height: 50px;
    background-color: white;
  }
  .sign-modal {
      width:90%
  }

  .sign-buttons {
      border-radius: 5rem;
      font-size: 1.2rem;
      padding-left:2rem;
      padding-right:2rem ;
      margin-right: 1rem;
      width: 40%;
  }
  .sign-header {
      color:#fff;
      text-align: center;
  }
  .sign-head {
      text-align: center;
  }
  .cross {

      margin-top: 1.5rem;
      margin-left: 1rem;
      font-size: 1.3rem;
  }
  #cross {
    float: right;
    background: 0;
    border: 0;
    color:#fff
  }
