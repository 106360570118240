.PortfolioDeposits {
  $base: &;
  &__grid,
    &__row_grid {
    gap: 32px;
    grid-template-columns: 2fr repeat(4, 1fr);
    font-size: 14px;
  }

  &__itemGrid {
    gap: 24px;
    grid-template-columns: 1.1fr 1.1fr 1.4fr 1.4fr;
    font-size: 14px;
  }

  &__gray {
    line-height: 1.25;
    color: #a7a7a7;
  }

  &__dark {
    color: #202020;
    line-height: normal;
  }

  &__logo {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }

  &__tile {
    padding: 30px 20px 20px;
    border-radius: 14px;
    background-color: #fff;
  }

  &__row {
    border-radius: 14px;
    background-color: #f7f7f7;
    padding: 12px 20px;
  }

  &__item {
    padding: 30px 20px;
  }

  &__folios {
    #{$base}__itemGrid:not(:nth-of-type(1)) {
      margin-top: 12px;
    }
  }
}

@media screen and (max-width: 992px) {
  .PortfolioDeposits {
    $base: &;

    &__header {
      display: none;
    }

    &__tile {
      background-color: transparent;
    }
  }
}