.my-sips-title {
}

.fund-wrapper {
  word-break: break-all;
  position: relative;
}

.display-menu {
  height: 35px;
  width: 15px;
  position: absolute;
  top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 10px;
}

.display-menu-title-text {
  font-size: 10px;
  margin-left: 5px;
  text-align: center;
  margin-bottom: 0;
}

.szh-menu__item {
  font-size: 10px;
  text-align: center;
  padding-top: 0.3em;
}

.szh-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;

  z-index: 100;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: 100;
  border-radius: 9px;
  width: 151px !important;
}

.menu-display-icons {
  background: transparent;
  border: none;
  margin: 0px;
}

.status-active {
  color: rgb(109, 235, 109);
}

.status-inactive {
  color: rgb(227, 227, 37);
}

.folio-number {
  font-size: 0.7em;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
}

.mandate {
  font-size: 0.7em;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
}

.invested-amount {
  font-size: 0.8em;
  font-weight: 600;
}

.mobile-sip-card-wrapper {
  position: relative;
}

.no-data-available {
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translate(-50%, -50%);
  font-size: 1em;
  font-weight: bold;
}

.fund-title {
  word-break: break-word;
}

.col-sm-55 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 45%;
  max-width: 45%;
}
.col-sm-25 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 21.66666667%;
  max-width: 21.66666667%;
}
