.section_title {
  font-size: 18px;
  font-weight: 600;
  color: #464646;

  span:first-child {
    color: #96A0B5;
  }
}

.rbt-menu {
  font-size: 14px !important;
  .dropdown-item{
    min-height: 40px;
    display: flex;
    align-items: center;
  }
}
