.Investments {
  &__emptyTile {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  &__empty {
    color: #202020;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
  }

  &__button_container {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    align-items: center;
  }

  &__emptyBtn {
    width: 100%;
    border-radius: 6px;
    background: #08aeea;
    color: #fff;
    padding: 12px 20px;
    transition: background-color 150ms;

    &:hover {
      color: #fff;
      background-color: darken($color: #08aeea, $amount: 5);
    }
  }

  &__transparentBtn {
    width: 100%;
    border-radius: 6px;
    background: transparent;
    color: #08aeea;
    padding: 12px 20px;
    border: 1px solid #08aeea;
    transition: background-color 150ms;

    &:hover {
      color: #fff;
      background-color: darken($color: #08aeea, $amount: 0);
    }
  }

  &__label {
    color: #bcbcbc;
    font-size: 14px;
    font-weight: 500;
  }

  &__deposits {
    padding: 30px;
  }

  &__amount {
    font-weight: 600;
  }

  &__cell {
    margin-top: 30px;
    display: flex;
      flex-direction: column;
      justify-content: space-between;
  }

  &__dropdown {
    &__btn {
      background: transparent !important;
      border: none !important;
      outline: none !important;
      color: #08aeea !important;
    }
  }

  &__tile {
    max-width: 262px;
  }
    &__overlay_chart {
      width: calc(100% - 30px);
        height: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(202, 202, 202, 0.5);
      color: #504c4c;
      font-weight: 600;
      font-size: 10px;
        text-align: center;
  
      z-index: 1;
      word-break: break-word;
      border-radius: 20px;
    }
&__overlay_chart>span {
  margin-inline: 20px;
  margin-block: 20px;
}
}
