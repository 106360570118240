.DashboardV2 {
  max-width: 1180px;
  padding: 30px 20px;
  margin: -20px -25px -20px -20px;
  .highcharts-credits {
    display: none;
  }

  &__heading {
    font-size: 26px;
  }

    &__summary_quick_links_wrapper {
      display: flex;
      flex-wrap: wrap;
      row-gap: 1.2rem;
    }
  
    &__title_wrapper {
      display: grid;
      grid-template-columns: 1fr auto;
    }
  
    &__quicklinks_wrapper {
      width: 100%;
      max-width: 458px;
    }
  &__title {
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #464646;
  }

    &__quick_action:hover,
    &__quick_action:active {
      background-color: #079cd1;
      color: #fff;
    }
  &__tile {
    border-radius: 20px;
    background: #fff;
  }

  &__assetTile {
    padding-top: 30px;
  }

  &__quicklinks {
    padding: 30px 20px 20px;
  }

  &__quicklinksTitle {
    font-size: 18px;
    margin-bottom: 18px;
  }

  &__assetTitle {
    font-size: 18px;
    padding-left: 30px;
  }

  &__gridItem {
    min-height: 115px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.28px;
  }
  &__orangeTile {
    border-radius: 15px;
    background: #fffaf4;
    color: #f8a544;
    &:hover {
      color: #f8a544;
      background-color: darken($color: #fffaf4, $amount: 3);
    }
    transition: background-color 150ms;
  }
  &__purpleTile {
    border-radius: 15px;
    background: #f9f8ff;
    color: #5434d2;
    &:hover {
      color: #5434d2;
      background-color: darken($color: #f9f8ff, $amount: 3);
    }
    transition: background-color 150ms;
  }
  &__blueTile {
    border-radius: 15px;
    background: #edfaff;
    color: #04739b;
    &:hover {
      color: #04739b;
      background-color: darken($color: #edfaff, $amount: 3);
    }
    transition: background-color 150ms;
  }
  &__greenTile {
    border-radius: 15px;
    background: #f0fff3;
    color: #76b680;
    &:hover {
      color: #76b680;
      background-color: darken($color: #f0fff3, $amount: 3);
    }
    transition: background-color 150ms;
  }

  &__quicklinksGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  &__row {
    margin: 0 -10px;
  }
    // &__col {
    //   // padding: 0 10px;
    // }
  &__investment {
    font-size: 14px;
    font-weight: 500;
    color: #898989;
  }
  &__investmentAmount {
    font-size: 18px;
    font-weight: 600;
  }

  &__investmentTile {
    .DepositsTile-container,
    .MutualFundsTile__container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px
    }
  }

  &__comingSoon {
    color: #96a0b5;
    font-size: 11px;
    font-weight: 500;
    margin-top: 5px;
    svg {
      height: 16px;
      width: 16px;
      margin-top: -2px;
    }
  }
        &__not_available_blur {
          filter: blur(10px);
        }
}
