.pill-button {
  height: 20px;
  width: 46px;
  margin: 0;
  //padding: 0;
  text-transform: capitalize;
  font-size: 10px;
  border-radius: 9px;
  border: none;
  font-weight: 600;
  padding: 3px 0;

  &.pill-button-success {
    background-color: #e6faf6;
    color: #1fc758;
  }
  &.pill-button-danger {
    background-color: #fff0f0;
    color: #ed413b;
  }
}

.invest-now-btn {
  width: max-content;
  height: fit-content;
  padding: 0.5rem 1rem !important;
  border: 1px solid #08aeea;
  border-radius: 6px;
  color: #08aeea;
  font-size: 12px;
  background: white;
}
.invest-now-btn:hover {
  opacity: 0.5;
}

.app-v2 {
  .btn {
    //height: 50px;
    //padding: 12px 50px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;
  }

  .btn-link {
    font-weight: 600;
  }

  .btn-primary {
    background: #08aeea;
    border: 2px solid #08aeea;
    color: white;
  }

  .btn-outlined {
    background: white !important;
    color: #08aeea !important;
  }
}
@media screen and (max-width: 992px) {
  .invest-now-btn {
    padding: 0.4rem 0.7rem !important;
  }
}
