.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.mandate-card-not-selected {
  height: 160px;
  max-width: 70%;
  width: 100%;
  border: 2px solid #d5d5d5;
  border-radius: 6px;
  margin-top: 2rem;
  overflow-y: auto;
  cursor: pointer;
  display: flex;
  position: relative;
}

.mandate-card-not-selected:hover {
  border: 2px solid #08aeea;
  transition: 0.2s ease-in;
}

.mandate-card-selected {
  height: 160px;
  max-width: 70%;
  width: 100%;
  border: 2px solid #08aeea;
  border-radius: 6px;
  margin-top: 2rem;
  overflow-y: auto;
  cursor: pointer;
  display: flex;
  position: relative;
}

.mandate-icon {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: #08aeea;
  margin-top: 0.5%;
  position: absolute;
  left: 1%;
  top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mandate-card-body {
  height: 120px;
  max-width: 85%;
  width: 100%;
  margin-left: 6rem;
  margin-top: 1rem;
  display: flex;
  gap: 1em;
}

.continue-btn-mandate {
  background-color: #08aeea;
  height: 45px;
  width: 27%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-weight: 600;
  margin-top: 1.7rem;
  margin-bottom: 1.7rem;
  cursor: pointer;
}

.text-card-bank-mandate {
  font-size: 0.9em;
}

.go-back {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
}

.icon-file {
  color: #ffffff;
  font-size: 32px;
}

@media screen and (max-width: 480px) {
  .mandate-card-not-selected {
    max-width: 90%;
  }

  .mandate-card-selected {
    max-width: 90%;
  }

  .continue-btn-mandate {
    width: 80%;
  }

  .text-card-bank-mandate {
    font-size: 11px;
  }
}
