.FixedDeposits {
  border-radius: 10px;
  padding: 30px 20px 20px;

  &__header {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.25;
    color: #464646;
  }

  &__grid {
    grid-template-columns: 2.5fr 1.2fr 1.5fr 1.2fr 1.2fr 136px;
    gap: 32px;
    padding-bottom: 12px;
    line-height: 1.25;
    color: #a7a7a7;
    border: none !important;
  }

  &__gridCell {
    font-size: 15px;
    line-height: 1.25;
  }

  &__exploreAll {
    cursor: pointer;
    font-size: 12px;
    line-height: 1.25;
    color: #08aeea;
    transition: opacity 150ms;

    &:hover {
      opacity: 0.5;
    }
  }
}

$breakpoint-mobile: 992px;

@media screen and (max-width: $breakpoint-mobile) {
  .FixedDeposits {
    padding: 30px 20px 20px;
  }
}