.annexture-container,
.transaction-list-section,
.top-fund-list,
.my-sips-list {
  .row {
    &:nth-child(2n + 1) {
      background: #ffffff 0% 0% no-repeat padding-box;
    }
    &:nth-child(2n) {
      background: #f9f9f9 0% 0% no-repeat padding-box;
    }
  }
}
.invest-section {
  border: 1px solid $color-08aeea;
  border-top: 3px solid #0093e9;
  border-radius: 5px 5px 10px 10px;
  .img-container {
    border: 1px solid $color-08aeea;
    border-radius: 10px;
    padding: 0.5rem;
    margin-bottom: 0.75rem;
    display: inline-block;
    img {
      width: 60px;
      line-height: 0;
      height: 60px;
    }
  }
}
.fund-return-row {
  border: 0.75px solid $color-707070;
  .return-item {
    flex-grow: 1;
    flex-basis: 0;
    border-right: 0.75px solid $color-707070;
  }
  .return-item-2 {
    flex-grow: 2;
    flex-basis: 0;
  }
}
.profile-container {
  .nav-link {
    cursor: pointer;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    border-radius: 10px;
  }
  .selected {
    color: #08aeea;
    background: #08aeea0f 0% 0% no-repeat padding-box;
  }
  .profile-pic-container {
    position: relative;
    .profile-pic {
      width: 75px;
      height: 75px;
      border: 2px solid #08aeea;
    }
    .file-field {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
.profile-container-mobile {
  .nav-link {
    cursor: pointer;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    border-radius: 10px;
  }
  .selected {
    color: #08aeea;
    background: #08aeea0f 0% 0% no-repeat padding-box;
  }
  .profile-pic-mobile {
    position: relative;
    .profile-mobile {
      width: 140px;
      height: 140px;
      border: 2px solid #08aeea;
    }
    .file-field {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
.chat-container {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 2;
  img {
    width: 100px;
    cursor: pointer;
  }
}

.heading-custom {
  font-size: 36px;
  line-height: 48px;
  color: $color-222222;
}
.fund-section {
  color: #222222;
  cursor: pointer;
}

.rotate-180 {
  transform: rotate(180deg);
}
.transactions-container,
.protfolio-list-container {
  box-shadow: 0px 5px 50px #70707017;
  position: relative;
}
.otp-input {
  min-width: 40px;
  text-align: center;
  font-weight: 600;
  margin-right: 0.5em;
  padding: 0;
}
.otp-mobile.otp-input {
  min-width: 30px !important;
}
.text-disc {
  -webkit-text-security: disc;
  -moz-text-security: disc;
}
.border-tl {
  border-top-left-radius: 2rem;
}

.border-tr {
  border-top-right-radius: 2rem;
}

.sip-btn {
  //height: 24px;
  width: 34px;
  padding: 4px !important;
  margin: 4px;
  font-size: 14px !important;
  color: #707070;

  &.btn-light {
    background: transparent;
    border: 1px solid white;
  }
  &.btn-primary {
    color: #ffffff;
  }

  &.btn-light:disabled {
    color: #9d9d9d !important;
  }
}

.dropdown-grey-bg{
  background: #eeeeee !important;
}

.btn-outline-dark{
  border-color: #1A1A1A;
}

.btn-outline-dark:hover{
  color: #1A1A1A;
  background: #ffffff;
  border-color: #1A1A1A;
}
.text-center{
  text-align: center;
}

@media (max-width:992px) {

  h6.invest-font {

    min-height: 38px;
  }
       
}

.funddetail {
  min-width: 277px;
  justify-content: flex-end !important;
}
@media (max-width:1518px) {
  .d-flex.align-items-baseline.h3.justify-content-end.funddetail {
    justify-content: space-around !important;
    margin-left: -20px;

  }
}
.font-weight-medium.error-message{
  font-size: 12px !important;
}
.document-upload{
  width: 87% !important;
}
.icon-del {
  width: 22px;
  padding-left: 2px;
}
.fund-detail-div span {
  font-size: 10px;
  font-weight: 400;
  margin: auto;
  color: #96A0B5;
}
.fund-detail-div span.unit {
  font-size: 12px;
  font-weight: 500;
  color: #262E4A;
}
.app-v2 .form-group .invalid-feedback{
  margin-left: 0 !important;
}