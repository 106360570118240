.font-medium {
    font-weight: 500;
}

.font-bold {
    font-weight: 700;
}

.text-gray-500 {
    color: #6b7280;
}


.flex {
    display: flex;
}

.items-center {
    align-items: center;
}

.justify-between {
    justify-content: space-between;
}

.hidden {
    display: none;
}

.order-first {
    order: -9999;
}

.xl-block {
    @media (min-width: 1280px) {
        display: block;
        width: 50%;
    }
}

.xl-hidden {
    @media (min-width: 1280px) {
        display: none;
        width: 50%;
    }
}

.order-first {
    display: block;
    order: -9999;
}

.block {
    display: block;
}

.mt-5 {
    margin-top: 1.25rem;
}

.RightColumn {
    $base: &;

    &_wrapper {
        padding-inline: 1.25rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media (min-width: 1280px) {
            justify-content: flex-start;
                align-items: flex-start;
        }
    }

    &_container {
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 3rem;
        background-color: #ffffff;
        // max-width: 357px;
        width: 100%;
        padding-top: 50px;
        display: flex;
        flex-direction: column;
        row-gap: 1.5rem;
        border-radius: 10px;
        margin-top: 1.25rem;

        @media (min-width: 1280px) {
            // max-width: 460px;
        }
    }

    &_primary_button {
        display: flex;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-block: 0.5rem;
        flex-grow: 1;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;
        border-radius: 0.375rem;
        font-size: 1rem;
        line-height: 1.25rem;
        font-weight: 700;
        text-align: center;
        color: #ffffff;
        background-color: #3b82f6;
        transition-property: background-color, border-color, color, fill, stroke,
            opacity, box-shadow, transform;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 300ms;
        outline: none;
        border: none;
    }

    &_primary_button:disabled {
        background-color: #9dc1fc;
        cursor: not-allowed;
        }

        &_payment_methods {
            &_container {
                display: flex;
                flex-direction: column;
                row-gap: 1rem;
            }
        
            &_label {
                font-weight: 700;
        
            }
        
            &_radio_buttons {
                display: flex;
                flex-direction: row;
                column-gap: 1rem;
                    align-items: center;
                
                    & label {
                        padding-inline: 0.3rem;
                    }
            }
    }
}

// Defined structure

.FDReview {
    $base: &;

    &_container_title {
        color: #202020;
        font-size: 1.625rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        flex-grow: 1;
        margin: 1.25rem;
    }

    &_container {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;

        @media (min-width: 1280px) {
            display: block;
        }
    }

    &_issuer_detail {
        display: flex;
        margin-top: 1.25rem;
        margin-bottom: 1.75rem;
        flex-direction: column;
        align-items: center;
        margin: 1.25rem;
        padding: 1rem;
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 500;
        background-color: transparent;
        margin-bottom: 30px;
        justify-content: center;
        border-radius: 10px;

        @media (min-width: 1280px) {
            align-items: center;
            flex-direction: row;
            background-color: #ffffff;
            justify-content: flex-start;
        }
    }



    &_issuer_logo {
        display: flex;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        border-radius: 9999px;
        // background-color: #ffffff;
        // box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
        height: auto;
        width: auto;
        padding: 10px;
        max-width: 300px;
        max-height: 300px;

        & img {
            width: 100%;
            height: 100%;
        }
    }

    &_issuer_name {
        font-weight: 700;
        line-height: 1.5rem;

        font-size: 16px;
        padding: 10px;
    }


    &_issuer_badges {
        display: flex;
        align-items: center;
    }

    &_holder_container {
        display: flex;
        margin: 1.25rem;
        flex-direction: column;
        column-gap: 1rem;
        justify-content: space-between;

        @media (min-width: 1280px) {
            flex-direction: row;
        }
    }

    &_holder_details {
        margin-bottom: 4rem;

        @media (min-width: 1280px) {
            margin-bottom: 0;
            width: 50%;
        }
    }



    &_holder_label {
        font-size: 1.125rem;
        line-height: 1.75rem;
        font-weight: 700;
        margin-bottom: 10px;
    }

    &_holder_issuer_name {
        padding: 1rem;
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 500;
        background-color: #ffffff;
        margin-bottom: 30px;
        border-radius: 10px;
    }


    &_investment {
        &_detail_label {
            font-size: 1.125rem;
            line-height: 1.75rem;
            font-weight: 700;
        }

        &_detail_wrapper {
            padding: 1.25rem;
            margin-bottom: 1.25rem;
            margin-top: 1.25rem;
            font-size: 0.875rem;
            line-height: 1.25rem;
            background-color: #ffffff;
            border-radius: 10px;

            & div {
                margin-block: 10px;
            }
        }

        &_amount {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &_amount_value {
            font-size: 18px;
            font-weight: 700;
        }

        &_label {
            width: max-content;
        }

        &_mobile_screen {
            @media (min-width: 1280px) {
                display: none;
            }
        }
    }

    &_mobile_screen {
        display: block;

        @media (min-width: 1280px) {
            display: none;
        }
    }

    &_consents_container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }

    &_consents_text {
        padding-top: 9px;
        font-size: 0.8rem;
        text-align: justify;
    }
&_payment_reference_input {
    padding: 0.8rem;
    outline: none;
    border: 1px solid #3b82f6;
    border-radius: 10px;
    width: 100%;
    font-size: 0.875rem;
}
&_payment_instructions_text {
    padding-top: 1rem;
    padding-inline: 0.5rem;
    font-size: 0.8rem;
    text-align: justify
}
}

/* Style only for the Payment Link Instructions Modal  */

#NEFT_RTGS_instructions h2,
h3 {
    font-size: 20px !important;
    font-weight: 700 !important;
}

#NEFT_RTGS_instructions p,
li {
    text-align: justify !important;
}

#NEFT_RTGS_instructions p,
li {
    margin-bottom: 10px !important;
}

#NEFT_RTGS_instructions ul {
    padding-left: 40px;
}


#NEFT_RTGS_instructions ol {
    list-style-type: decimal;
    padding-left: 40px;
}