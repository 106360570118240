.app-v2 {
  .quick-links {
    margin: 10px -10px -10px -10px !important;
    .quick-link-row {
      display: flex;
      width: 100%;
      .quick-link-item {
        cursor: pointer;
        height: 138px;
        min-width: 138px;
        width: 100%;
        //max-width: 46%;
        border-radius: 20px;
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 10px 10px;

        .quick-link-icon {
          height: 55px;
          width: 55px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
        }

        .quick-link-label {
          font-size: 11px;
          font-weight: bold;
          margin-top: 10px;
        }

        .quick-link-note {
          color: #96A0B5;
          font-size: 11px;
          font-weight: 500;
          margin-top: 5px;
          svg {
            height: 16px;
            width: 16px;
            margin-top: -2px;
          }
        }
      }
    }
  }
}