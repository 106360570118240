.Banner-wrapper {
  height: 55px;
  margin-top: 0;
  display: 'flex';
  flex-grow: 1;
  background-color: rgb(64, 235, 64);
  color: #fff;
  width: 101%;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  flex-direction: row;
  font-weight: 700;
  justify-content: space-between;
}

.bsx-circle {
  margin-right: 16px;
  cursor: pointer;
}
