.FolioBtn {
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  transition: opacity 150ms;
  padding: 0 10px;
  &:hover {
    opacity: 0.5;
  }
}
