@import 'bootstrap';
@import 'izitoast/dist/css/iziToast.min.css';
@import './scss/fonts.scss';
body {
  margin: 0;
  font-size: 16px;
  color: #222222;
  font-family: 'Montserrat', sans-serif;
    // font-family: 'Sofia Pro';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: transparent;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}
