.switch-fund-card {
  margin-top: 20px;
  border-radius: 20px;
  display: flex;
  height: calc(100vh - 180px);

  .switch-fund-form {
    background: #FFFFFF;
    height: 100%;
    width: 50%;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    padding: 30px 80px;
    overflow: auto;

    input[type=checkbox] {
      margin-top: 7px;
    }
    input[type=checkbox]:checked {
      accent-color: #262E4A;
      color: #262E4A;
    }
    input[type=checkbox]:checked + label{
      accent-color: #262E4A;
      color: #262E4A;
    }

    .rbt {
      .rbt-input {
        padding-right: 20px;
      }

      .toggle-button {
        padding-right: 0;
        font-size: 22px;
        padding-bottom: 8px;
        color: black;
        &.open {
          padding-top: 8px;
          padding-bottom: 0 !important;
        }
      }
    }

    .switch-to-note {
      font-size: 10px;
      margin-left: 14px;
      color: #262E4A;
      font-weight: 500;
      //max-width: 400px;
    }

    .switch-fund-radio {
      label {
        margin-bottom: 0;
        margin-top: 4px;
      }
    }

    .switch-fund-btn {
      height: 50px;
      width: 250px;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .switch-fund-info {
    background: #E6FAF6;
    height: 100%;
    width: 50%;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .switch-fund-info-title {
    font-size: 18px;
    color: #262E4A;
    font-weight: bold;
    max-width: 300px;
    margin: 20px auto;
  }
  .switch-fund-info-description {
    font-size: 14px;
    color: #262E4A;
    font-weight: normal;
    max-width: 380px;
    margin-top: 20px;
  }
}

.processing-div {
  height: calc(100vh - 82px);
  display: flex;
  align-items: center;
  justify-content: center;

  .processing-block {
    height: 400px;
    width: 500px;
    background: white;
    border-radius: 20px;
    text-align: center;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    .processing-loader {
      img {
        height: 80px;
        width: 80px;
        animation: rotation 2s infinite linear;
      }
    }
    .processing-text {
      font-size: 24px;
      color: #070508;
      font-weight: bold;
    }
    .processing-text-sub {
      font-size: 16px;
      color: #898989;
      font-weight: normal;
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.confirm-switch-logo {
  height: 90px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFF9E6;
  border-radius: 50%;
}


@media only screen and (min-width:320px) and (max-width:992px) {
  .switch-fund-card {
    margin-top: 20px;
    border-radius: 20px;
    display: block;
    height: auto;

    .switch-fund-form {
      width: 100% !important;
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding: 15px 30px;
      overflow: auto;

    }

    .switch-fund-info {
      padding-top: 20px;
      padding-bottom: 20px;
      margin-bottom: 40px;
      background: #E6FAF6;
      width: 100% !important;
      border-bottom-right-radius: 20px;
      border-top-right-radius: 0 !important;
      border-bottom-left-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
}

.switch-fund-otp-modal {
  .otp-text {
    font-size: 14px !important;
    font-weight: 500 !important;
    button {
      font-size: 14px !important;
      font-weight: 500 !important;
    }
  }
  .otp-text-light {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #96A0B5;
  }
}