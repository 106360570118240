.ResultModal {
    $base: &;
    
    &__body_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        text-align: center;
        row-gap: 1rem;
        font-size: 0.875rem;
    }
}