.ExternalFunds {
    $base: &;

    &__section_title {
        display: flex;
        font-size: 18px;
        font-weight: 600;
        color: #464646;

        span:first-child {
            color: #96A0B5;
        }

    }

    &__default_tile {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        padding: 2rem 1rem;
        border-radius: inherit;
        text-align: center;

        &_wrapper {
            width: 80%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            row-gap: 1rem;
        }

        &_heading {
            font-size: 1.125rem;
            font-weight: 600;
        }

        &_instructions {
            font-size: 0.875rem;
            font-weight: 500;
        }

        &_button {
            margin-top: 1rem;
            display: flex;
            max-width: 230px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            row-gap: 0.875rem;
            padding: 50px 29px;
            background: #F5F7F9 0% 0% no-repeat padding-box;
            border-radius: 10px;
            opacity: 1;
            color: #2E2C34;

            &:hover {
                background-color: #ECEDFD;
                color: #2E2C34;

            }
        }
    }

}