.GroupDetail {
    $base: &;
    $font-black: #202020;
    $font-grey: #BCBCBC; // Heading text
    $background-color-white: #FFF;

    color: $font-black;

    // Define the variable for better control & maintainability
    // 1. font size
    // 2. font color. e.g., primary, secondary
    // For Search input 
    &_search_container {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        min-width: 12rem;
        width: 100%;
        border-radius: 0.375rem;
        border: 1px solid #DCDCDC;
        background: $background-color-white;
        height: fit-content;

        >img {
            margin: 0.75rem 0.94rem;
        }

        >input {
            // height: 100%;
            outline: none;
            border: none;
            border-radius: 0.375rem;
            width: 100%;
        }
    }

    &_header {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;

        &_section1_wrapper {
            display: flex;
            flex-direction: column;
        }

        &_created_by_text {
            font-size: 0.875rem;
            font-weight: 600;
        }
    }


    &_header_title {
        font-size: 1.625rem;
        font-weight: 600;

    }

    &_horizontal_divider {
        display: flex;
        justify-content: flex-start;
        border-bottom: 1.2px solid rgb(220, 220, 220);
        margin-block: 1.8rem;
    }

    &_vertical_divider {
        height: 100%;
        margin-inline: 0.5rem;
        border-right: 1.2px solid rgb(220, 220, 220);
    }

    &_table_container {
        padding-inline: 1.26rem;
        padding: 1.26rem;
        background: $background-color-white;
        border-radius: 0.75rem;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
        min-width: 844px;
        overflow-x: auto;
    }

    // ************* Grid's style *************
    &_table {
        display: flex;
        flex-direction: column;
        text-align: center;


        &_edit_button {
            outline: none;
            border: none;
            background-color: $background-color-white;
            color: #007bff;
        }

        &_edit_button:hover {
            color: #0056b3;
        }

        &_header {

            display: grid;
            grid-template-columns: 1fr 1fr 2fr 1.5fr 1fr;
            padding-bottom: 0.8rem;
            font-size: 0.875rem;
            color: $font-grey;
        }

        &_collapsable_icon {
            width: fit-content;
            padding: 0.38rem 0.44rem 0.44rem 0.38rem;
            border-radius: 0.625rem;
            background: #F5F5F5;
            border: none;
            margin-block: 0.94rem;
        }

        &_collapsable_icon>svg {
            width: 1.5rem;
            height: 1.5rem;
        }

        &_rows {
            display: flex;
            flex-direction: column;
        }

        &_row_field_actions {
            display: flex;
            justify-content: center;
            column-gap: 0.5rem;
            align-items: center;
        }

        &_row {
            display: grid;
            grid-template-columns: 1fr 1fr 2fr 1.5fr 1fr;
            border-top: 1px solid #F5F5F5;
            padding-block: 0.8rem;
            font-size: 0.875rem;
        }

        &_no_data {
            margin-block: 1rem;
            color: $font-black;
        }
    }
}