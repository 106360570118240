.toggle-switch {
  border-radius: 24px !important;
  padding: 2px;
  border: 1px solid #707070;
  .toggle-button {
    padding: 4px 12px;
    line-height: 1.3;
    font-size: 14px;
    text-decoration: none !important;
    color: #222222;

    text-transform: capitalize;
  }
  .button-selected {
    background-color: #08aeea !important ;
    border-radius: 24px !important;
    color: #ffffff;
  }
}
