.modal-header,
.modal-footer {
  border: none;
}
.modal-header {
  display: flex;
  align-items: center;
}
.btn-primary {
  padding: 0.375rem 1rem;
  background-color: $color-08aeea !important;
  border-color: $color-08aeea !important;
  box-shadow: none !important;
}
.btn-outline-primary {
  padding: 0.375rem 1rem;
  color: $color-08aeea;
  border-color: $color-08aeea !important;
  &:hover {
    background-color: $color-08aeea !important;
    border-color: $color-08aeea;
  }
}
.border-bottom {
  border-bottom: 1px solid $color-707070;
}
.border-top {
  border-top: 1px solid $color-707070;
}
.border-right {
  border-right: 1px solid $color-707070;
}
.border-left {
  border-left: 1px solid $color-707070;
}
.btn:focus {
  box-shadow: none !important;
}
input.form-control,
select.form-control,
body .dropdown-list .dropdown-toggle {
  color: $color-222222 !important;
  border-width: 1px;
}
.form-control:disabled,
.form-control[readonly] {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}
.form-label {
  color: $color-222222;
}
.mb-6 {
  margin-bottom: 6rem;
}
.mt-6 {
  margin-top: 6rem;
}
.mx-5 {
  margin-left: 5rem;
  margin-right: 5rem;
}
.d-flex-end {
  display: flex;
  justify-content: flex-end;
}
.order-3 {
  order: 3;
}
.disabled {
  color: $color-999999;
}
.right-align {
  position: absolute;
  right: $size1;
}
.right-align-2 {
  position: absolute;
  right: $size2;
}
.enabled {
  color: $color-08aeea;
}

.link-disabled {
  @extend .disabled;
  padding: 0rem 0rem;
}

.link-enabled {
  @extend .enabled;
  padding: 0rem 0rem;
}
.progress {
  height: 5px;
}
.dropdown-divider {
  margin: 0.1em 0;
}
.dropdown-toggle.disabled {
  pointer-events: none;
  cursor: not-allowed;
}
.custom-radio:checked ~ label::before {
  background-color: #ffffff !important;
  border-color: #08aeea !important;
}
.custom-radio:checked ~ label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='4' fill='%2308AEEA'/%3e%3c/svg%3e") !important;
}
.graph-buttons .btn-link.active {
  color: #007bff;
  text-decoration: underline;
}
.popover {
  border: none !important;
  box-shadow: 9px 9px 36px #70707033;
}
.download-icon {
  background-color: white !important;
}
.btn-width {
  width: 10vw;
}
.breadcrumb {
  background-color: transparent !important;
}
@media (max-width:992px){
  .btn-width {
    width: 45%;
  }
}
